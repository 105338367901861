<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" />
          <span>Genel Bilgi</span>
        </template>
        <b-card>
          <service-category />
          <title-text />
          <summary-text />
          <content-text />
          <ordering />
        </b-card>
        <image-card />
      </b-tab>
      <b-tab
        :disabled="!dataItem.id"
        lazy
      >
        <template #title>
          <FeatherIcon icon="SettingsIcon" />
          SEO Ayarları
        </template>
        <seo-form
          :item-id="dataItem.id"
          id-components="12"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BCard, BTab, BTabs,
} from 'bootstrap-vue'

import ServiceCategory from '@/views/Admin/Services/elements/ServiceCategory.vue'
import TitleText from '@/views/Admin/Services/elements/Title.vue'
import SummaryText from '@/views/Admin/Services/elements/Summary.vue'
import ContentText from '@/views/Admin/Services/elements/Content.vue'
import ImageCard from '@/views/Admin/Services/elements/Image.vue'
import Ordering from '@/views/Admin/Services/elements/Ordering.vue'
import SeoForm from '@/views/Admin/Seo_configs/ItemForm.vue'

export default {
  name: 'ItemForm',
  components: {
    SeoForm,
    BTab,
    BTabs,
    BCard,
    ServiceCategory,
    TitleText,
    SummaryText,
    ContentText,
    ImageCard,
    Ordering,
  },
  computed: {
    dataItem() {
      return this.$store.getters['services/dataItem']
    },
  },
}
</script>
